import React from 'react';
import ReactDOM from 'react-dom';
import reactToWebComponent from 'react-to-webcomponent';
import '@webcomponents/custom-elements';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, from, ApolloLink, concat } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import cookieUtils from './utils/cookie';

// Since web components have no idea about NODE_ENV we check for environment by url
const origin = window.location.origin;
const isLocal = origin.includes('webviews.local.external.mojo.com');
const isStage = origin.includes('privatereviewsite.webflow.io');
const isTestLeaderboard = (origin.includes('mojo.com') || origin.includes('privatereviewsite.webflow.io')) && window.location.pathname.includes('mojo-leaderboard');

if (cookieUtils.getCookie('mojo-auth')) {
  // set local storage with auth cookie
  localStorage.setItem('mojo-auth', JSON.stringify({ accessToken: cookieUtils.getCookie('mojo-auth') }));

  // delete cookie after set in localStorage
  cookieUtils.deleteCookie('mojo-auth', '.mojo.com');
}

const authPages = ['mojo-leaderboard', isLocal ? 'iframe.html' : ''];
const includeAuth = window.location.pathname.split('/').some((item) => authPages.includes(item));

const mojoToken = localStorage.getItem('mojo-auth');
const { accessToken } = mojoToken && includeAuth ? JSON.parse(mojoToken) : { accessToken: null };

const bearerTokenString = accessToken ? `Bearer ${accessToken}` : '';

import Leaderboard from './components/Leaderboard';
import MojoLogo from './components/MojoLogo';
import StockTicker from './components/StockTicker';
import ReferralForm from './components/ReferralForm';
import { IterableEmailForm, IterableForm } from './components/IterableForm';

// Retry on network errors
// https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
const retryLink = new RetryLink({
  delay: (count) => {
    return count * 2500 * Math.random();
  },
  attempts: (count, _, error) => {
    // Log the network error and count
    console.log(`Network error: retry #${count}`);

    return !!error && count <= 3;
  },
});

const httpLink = new HttpLink({
  uri: isTestLeaderboard
    ? 'https://graphql-dfs.use1.stg.mojo.com'
    : isLocal
    ? 'https://graphql-dfs.use1.stg.mojo.com' //
    : isStage
    ? 'https://graphql.stg.external.mojo.com/'
    : 'https://graphql.prd.external.mojo.com/',
});

const link = from([retryLink, httpLink]);

const authorization = accessToken ? { authorization: bearerTokenString } : {};

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...authorization,
    },
  }));

  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  headers: { 'apollographql-client-name': 'mojo-web-components' },
  link: concat(authMiddleware, link),
  credentials: 'include',
});

export const GatewayWrapper = ({ children }: any) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

enum LeagueAbbreviation {
  MLB,
  NBA,
  NCAAF,
  NCAAMB,
  NHL,
  WNBA,
}

const LeaderboardWithApollo = () => {
  return (
    <GatewayWrapper>
      <Leaderboard />
    </GatewayWrapper>
  );
};

interface StockTickerProps {
  speed?: number;
  gradient?: boolean;
  gradientColor?: string;
  includeHeadshot?: boolean;
  includeAthleteDetails?: boolean;
  leagueAbbreviation?: LeagueAbbreviation;
  quantity?: number;
}
// Make components with apollo wrappers
const StockTickerWithApollo = (props: StockTickerProps) => {
  const gradientColor = props?.gradientColor?.split(',');

  return (
    <GatewayWrapper>
      <StockTicker tickerProps={{ ...props, gradientColor }} />
    </GatewayWrapper>
  );
};

interface EmailFormProps {
  buttonText?: string;
  placeholderText?: string;
  successSlug?: string;
  errorSlug?: string;
}

const ReferralFormWithApollo = (props: EmailFormProps) => {
  return (
    <GatewayWrapper>
      <ReferralForm {...props} />
    </GatewayWrapper>
  );
};

interface IterableFormProps {
  buttonText?: string;
  placeholderText?: string;
  listId: number;
  emailAddress?: string;
  phoneNumber?: string;
  redirectUrl?: string;
}

const IterableEmailFormWithApollo = (props: IterableFormProps) => {
  return (
    <GatewayWrapper>
      <IterableEmailForm {...props} />
    </GatewayWrapper>
  );
};

const IterableFormWithApollo = (props: IterableFormProps) => {
  return (
    <GatewayWrapper>
      <IterableForm {...props} />
    </GatewayWrapper>
  );
};

// Convert React to webComponents

const Logo = reactToWebComponent(MojoLogo, React as any, ReactDOM as any);
customElements.define('mojo-logo', Logo as any);

const MojoLeaderboard = reactToWebComponent(LeaderboardWithApollo as any, React as any, ReactDOM as any);
customElements.define('mojo-leaderboard', MojoLeaderboard as any);

const Ticker = reactToWebComponent(StockTickerWithApollo as any, React as any, ReactDOM as any, {
  props: {
    speed: Number,
    color: String,
    gradient: Boolean,
    gradientColor: String,
    leagueAbbreviation: String,
    quantity: Number,
    includeHeadshot: Boolean,
    includeAthleteDetails: Boolean,
  },
});
customElements.define('stock-ticker', Ticker as any);

const Referral = reactToWebComponent(ReferralFormWithApollo as any, React as any, ReactDOM as any, {
  props: {
    buttonText: String,
    placeholderText: String,
    successSlug: String,
    errorSlug: String,
  },
});
customElements.define('referral-form', Referral as any);

const IterableEmail = reactToWebComponent(IterableEmailFormWithApollo as any, React as any, ReactDOM as any, {
  props: {
    buttonText: String,
    placeholderText: String,
    listId: Number,
  },
});
customElements.define('email-form', IterableEmail as any);

const IterableGeneric = reactToWebComponent(IterableFormWithApollo as any, React as any, ReactDOM as any, {
  props: {
    buttonText: String,
    placeholderText: String,
    listId: Number,
    isPhone: Boolean,
    redirectUrl: String,
  },
});
customElements.define('iterable-form', IterableGeneric as any);
