import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

export default function Loader({ button = false, color = '#000' }: { button?: boolean; color?: string }) {
  // I do not like this. Need a better way to control the color and size than a className prop?
  return (
    <StyledLoaderWrapper>
      <StyledSpinner>
        {Array.from(Array(4).keys()).map((item: any, idx: number) => (
          <StyledLoader key={item} color={color} button={button} />
        ))}
      </StyledSpinner>
      <SROnly>Loading...</SROnly>
    </StyledLoaderWrapper>
  );
}

const StyledLoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
`;

const StyledSpinner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
`;

const StyledLoader: any = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 100%;
  width: ${(props: any) => (props.button ? '16px' : '36px')};
  height: ${(props: any) => (props.button ? '16px' : '36px')};
  margin-top: ${(props: any) => (props.button ? '-8px' : '-18px')};
  margin-left: ${(props: any) => (props.button ? '-8px' : '-18px')};
  border: 2px solid;
  border-color: ${(props) => props.color} transparent transparent transparent;
  animation: ${spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

  &:nth-of-type(1) {
    animation-delay: -0.45s;
  }

  &:nth-of-type(2) {
    animation-delay: -0.3s;
  }

  &:nth-of-type(3) {
    animation-delay: -0.15s;
  }
`;

const SROnly = styled.div`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`;
