export default function MojoLogo({ light, mono, width }: any) {
  return (
    <svg viewBox="0 0 146 33" width={width}>
      <path
        fill={light ? (mono ? '#000000' : '#90D110') : mono ? '#FFFFFF' : '#BFFF00'}
        d="M46.7,17c-0.9-0.6-3-1.2-4.8-1.2c-1.6,0-5.9,3.7-8.9,6.9c2-5.9,5.8-13.4,9.7-18.7
	c0.4-0.5,0.3-0.8-0.1-1.1c-0.9-0.8-3.8-1.9-6-1.9c-1.3,0-3.5,1.4-5.8,3.3c-4.2,3.4-8.1,7.2-11.6,11.3c0.4-1.9,1-3.9,1.6-6.2
	c0.5-1.9,0.9-3.3,0.9-3.6c0-0.7-3.4-2-4.8-2c-3,0-10.5,7.6-14.5,13.1C1.4,18,0,19.8,0,20.4c0,0.8,3.8,1.7,4.5,1.7
	c0.4,0,0.6,0,1.1-0.6c3.7-5.1,5.3-6.9,8.9-10.5c-0.9,4.2-1.6,8.3-1.6,9.7c0,1.3,4.2,2.6,5.3,2.6c0.5,0,1,0,1.9-1.1
	c3.5-4.2,7.7-9.3,11.8-12.7c-4.2,7.9-7.1,17.4-7.1,19.5c0,1.5,5.8,3.3,6.9,2.9c0.5-0.2,0.8-0.6,1.7-1.8c4.1-5.3,8.2-9.6,13.2-12.7
	C47,17.3,46.9,17.1,46.7,17z"
      />
      <path
        fill={light ? '#000000' : '#FFFFFF'}
        d="M76.5,8.2l-5.3,8.3H71l-1-8.3H59.5l-4,15.5h6.1l2.9-11.3h0.2l1.6,11.3h5.4l7.5-11.3h0.2l-2.9,11.3h6.1
	l4-15.5C86.6,8.2,76.5,8.2,76.5,8.2z M100.2,7.9h-1.3c-7,0-10.3,2-11.8,7.6l-0.3,0.9C85.3,22,87.6,24,94.6,24H96
	c7,0,10.3-2.1,11.8-7.7l0.2-0.9C109.5,9.9,107.3,7.9,100.2,7.9z M101.7,15.6l-0.2,0.7c-0.6,2.3-1.4,3.4-4.9,3.4h-0.4
	c-3.6,0-3.6-1.2-3.1-3.4l0.2-0.7c0.6-2.3,1.4-3.4,4.9-3.4h0.4C102.2,12.2,102.3,13.4,101.7,15.6L101.7,15.6z M123.9,8.2h-12l-1,4
	h5.6l-1.2,4.6c-0.6,2.2-1.1,2.5-3.2,2.5H109l-1.1,4.4h5.2c5.5,0,7.2-1.7,8.5-6.5L123.9,8.2z M137.6,7.9h-1.3c-7,0-10.3,2-11.8,7.6
	l-0.3,0.9C122.8,22,125,24,132,24h1.3c7.1,0,10.3-2.1,11.8-7.7l0.3-0.9C146.9,9.9,144.6,7.9,137.6,7.9z M139.1,15.6l-0.2,0.7
	c-0.6,2.3-1.4,3.4-4.9,3.4h-0.4c-3.5,0-3.6-1.2-3.1-3.4l0.2-0.7c0.6-2.3,1.3-3.4,4.9-3.4h0.4C139.5,12.2,139.7,13.4,139.1,15.6
	L139.1,15.6z"
      />
    </svg>
  );
}
