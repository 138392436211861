import { useRef, useEffect } from 'react';

type PollingOptions = {
  interval: number;
  callback: () => void;
};

type PollingControls = {
  start: () => void;
  stop: () => void;
  setInterval: (interval: number) => void;
  cleanup: () => void;
};

export default function usePolling({ interval, callback }: PollingOptions): PollingControls {
  const pollingRef = useRef<number | null>(null);
  const lastTime = useRef<number | null>(null);

  const autoPolling = (time: DOMHighResTimeStamp) => {
    const elapsed = lastTime.current ? time - lastTime.current : 0;

    if (elapsed >= interval || !lastTime.current) {
      try {
        callback();
      } catch (error) {
        console.error('Polling error:', error);
      }

      lastTime.current = time;
    }

    pollingRef.current = requestAnimationFrame(autoPolling);
  };

  const startPolling = () => {
    if (!pollingRef.current) {
      pollingRef.current = requestAnimationFrame(autoPolling);
    }
  };

  const stopPolling = () => {
    if (pollingRef.current) {
      cancelAnimationFrame(pollingRef.current);
      pollingRef.current = null;
    }
  };

  const setInterval = (newInterval: number) => {
    interval = newInterval;
  };

  useEffect(() => {
    startPolling();
    return stopPolling;
  }, []);

  const cleanup = () => {
    stopPolling();
  };

  return { start: startPolling, stop: stopPolling, setInterval, cleanup };
}
