import { Global, css } from '@emotion/react';

export default function GlobalStylesWrapper({ children }: any) {
  return (
    <>
      <Global styles={GlobalStyles} />
      <div id="componentWrapper">{children}</div>
    </>
  );
}

const GlobalStyles = css`
  /* Apply overrides only to the components sp page css is unaffected by global resets */

  #componentWrapper * {
    webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  #componentWrapper {
    font-family: 'F37zagmabook', Helvetica, sans-serif;
  }

  #componentWrapper button {
    font-family: 'SlussenExtended', sans-serif;
  }

  #componentWrapper input {
    font-family: 'F37zagmamono', sans-serif;
  }

  #componentWrapper button,
  #componentWrapper input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    box-shadow: none;
  }
`;
