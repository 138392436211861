import styled from '@emotion/styled';
import { Fragment, useState } from 'react';

import GlobalStylesWrapper from '../GlobalStylesWrapper';

const DotSpacer = () => <StyledSpacer>•</StyledSpacer>;

export const TickerAthlete = ({ stock, includeHeadshot = true, includeAthleteDetails = true }: any) => {
  const [imgError, setImgError] = useState(false);

  const { id: stockId, athlete, currentPriceFormatted, dailyChangeDollarsFormatted, dailyChangePercentage, dailyChangePercentageFormatted, type } = stock || {};
  const { firstName, lastName, mojoPosition, headshotUrl, leagueAbbreviation, team, jerseyNumber } = athlete || {};
  const { abbreviation: teamAbbreviation } = team || {};

  const la = leagueAbbreviation === 'NCAAF' ? leagueAbbreviation : null;
  const athleteDetails = [la, teamAbbreviation, mojoPosition, jerseyNumber].filter((detail) => detail !== undefined && detail !== '' && detail !== null);

  return (
    <GlobalStylesWrapper>
      <StyledAthleteWrapper key={stockId}>
        {includeHeadshot ? (
          <StyledHeadshotWrapper>
            {imgError ? <StyledHeadshot src={`https://static.mojo.com/${la ? 'nfl' : leagueAbbreviation.toLowerCase()}/headshots/full/default.png`} alt="default headshot" /> : <StyledHeadshot onError={() => setImgError(true)} src={headshotUrl} alt={firstName + ' ' + lastName} />}
          </StyledHeadshotWrapper>
        ) : null}
        <StyledDetailsWrapper includeHeadshot={includeHeadshot} includeAthleteDetails={includeAthleteDetails} className={!includeHeadshot && !includeAthleteDetails ? 'inline' : ''}>
          <StyledAthleteName>{`${firstName} ${lastName}`}</StyledAthleteName>
          {/* Athlete Details */}
          {includeAthleteDetails && type !== 'TEAM_BET' ? (
            <StyledAthleteDetails>
              {athleteDetails.map((detail: any, index: number) => {
                const includeDotSpacer = athleteDetails.length > 1 && athleteDetails.length - 1 !== index;

                return (
                  <Fragment key={index}>
                    {detail}
                    {includeDotSpacer ? <DotSpacer /> : null}
                  </Fragment>
                );
              })}
            </StyledAthleteDetails>
          ) : null}

          <StyledPrice>{`${currentPriceFormatted}`}</StyledPrice>

          <StyledPercentage dailyChangePercentage={dailyChangePercentage}>{`${dailyChangeDollarsFormatted}(${dailyChangePercentageFormatted})`}</StyledPercentage>
        </StyledDetailsWrapper>
      </StyledAthleteWrapper>
    </GlobalStylesWrapper>
  );
};

const StyledAthleteWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 60px;
  color: #fff;
`;

const StyledHeadshotWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  margin-right: 8px;
  border-radius: 100%;
  overflow: hidden;
`;

const StyledHeadshot = styled.img`
  flex-shrink: 0;
  object-fit: cover;
  width: 58px;
  height: 58px;
  background-color: #4c4f53;
  border-radius: 100%;
`;

const StyledDetailsWrapper: any = styled.div`
  display: flex;
  flex-direction: ${(props: any) => (props.includeHeadshot || props.includeAthleteDetails ? 'column' : 'row')};
  align-items: ${(props: any) => (props.includeHeadshot || props.includeAthleteDetails ? 'start' : 'center')};

  &.inline > * + * {
    position: relative;
    margin-left: 12px;
    padding-left: 12px;

    &::before {
      color: #fff;
      font-size: 16px;
      position: absolute;
      left: -6px;
      content: '•';
    }
  }
`;

const StyledAthleteDetails = styled.div`
  display: flex;
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
`;

const StyledSpacer = styled.div`
  padding: 0 4px;
`;

const StyledAthleteName = styled.div`
  white-space: nowrap;
`;

const StyledPrice = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-family: monospace;
`;

const StyledPercentage: any = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-family: monospace;
  color: ${(props: any) => (props.dailyChangePercentage > 0 ? '#BFFF00' : '#FF192D')};
`;
