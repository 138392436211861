import { useState, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
//https://github.com/justin-chu/react-fast-marquee
import Marquee from 'react-fast-marquee';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import GlobalStylesWrapper from '../GlobalStylesWrapper';
import { TickerAthlete } from './TickerAthlete';
import Loader from '../Loader';

import { TOP_MOVERS_STOCKS_QUERY } from '../../graphql/queries';

export default function StockTicker({ tickerProps }: any) {
  const { loading, error, data: topMoverStocksData, startPolling, stopPolling } = useQuery(TOP_MOVERS_STOCKS_QUERY, { variables: { leagueAbbreviation: tickerProps.leagueAbbreviation, quantity: tickerProps.quantity } });

  const [data, setData] = useState([]);
  const memoData = useMemo(() => data, [data]);

  useEffect(function startAndStopPolling() {
    startPolling(50000);

    return () => stopPolling();
  }, []);

  useEffect(
    function randomAthletesFromData() {
      if (topMoverStocksData) {
        const topMovers = [...topMoverStocksData.topMovers];
        const random: any = [];

        while (topMovers.length !== 0) {
          const randomIndex = Math.floor(Math.random() * topMovers.length);
          random.push(topMovers[randomIndex]);
          topMovers.splice(randomIndex, 1);
        }

        setData(random);
      }
    },
    [topMoverStocksData],
  );

  return (
    <GlobalStylesWrapper>
      <StyledTickerWrapper>
        <StyledInnerWrapper>
          {error ? (
            <>{`There was an error fetching data :(`}</>
          ) : loading || !data ? (
            <Loader color="#FFF" />
          ) : (
            <Marquee speed={tickerProps.speed} gradient={tickerProps.gradient} gradientColor={tickerProps.gradientColor}>
              {memoData.map((player: any, idx: number) => (
                <TickerAthlete stock={player} key={idx} includeHeadshot={tickerProps.includeHeadshot} includeAthleteDetails={tickerProps.includeAthleteDetails} />
              ))}
            </Marquee>
          )}
        </StyledInnerWrapper>
      </StyledTickerWrapper>
    </GlobalStylesWrapper>
  );
}

const StyledTickerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100px;
`;

const StyledInnerWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 100%;
  color: #fff;
`;
