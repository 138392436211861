import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: F37zagma, sans-serif;
  color: white;
`;

const ListHeader = styled.nav`
  display: flex;
  position: sticky;
  top: 0;
  background-color: #000;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 1rem;
  font-family: 'SlussenExtended', sans-serif;
  font-weight: 900;
`;

const ListItem = styled.div<{ position: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 1rem 10px 0;
  height: ${(props) => (props.position === 1 ? '46px' : '36px')};
  background-color: ${(props) => {
    if (props.position === 1) {
      return 'rgba(31, 41, 153, 1)';
    } else {
      const alpha = 0.7 - (props.position - 2) * 0.1;
      return `rgba(31, 41, 153, ${alpha})`;
    }
  }};

  & > * {
    flex: 1;
  }
`;

const PositionListItem = styled(ListItem)<{ position?: number; isOutOfView?: boolean }>`
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: #2945cc;
  position: ${(props) => (props.isOutOfView ? 'sticky' : 'relative')};
  bottom: ${(props) => (props.isOutOfView ? '0' : 'auto')};
  z-index: ${(props) => (props.isOutOfView ? '1' : 'auto')};

  /* Define the animation */
  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  /* Apply the animation when the item is out of view */
  animation: ${(props) => (props.isOutOfView ? 'slide-up 0.5s ease-in-out forwards' : 'none')};
`;

const Rank = styled.div<{ position?: number; isOutOfView?: boolean; isHeader?: boolean }>`
  font-family: 'SlussenExtended', sans-serif;
  color: ${({ isOutOfView, isHeader }) => (isHeader || isOutOfView ? '#ffffff' : '#3377ff')};
  max-width: ${({ isHeader }) => (isHeader ? '60px' : '50px')};
  font-size: ${({ position, isHeader }) => (isHeader ? '16px' : position === 1 ? '26px' : '19px')};
  line-height: ${({ position, isHeader }) => (isHeader ? '14px' : position === 1 ? '32px' : '23px')};
  width: 34px;
  text-align: ${({ isHeader }) => (isHeader ? 'left' : 'center')};
`;

const PortfolioValue = styled.div<{ position?: number; isHeader?: boolean }>`
  text-align: right;
  flex: 1;
  font-family: ${({ isHeader }) => (isHeader ? '"SlussenExtended", sans-serif' : '"F37zagmamono", sans-serif')};
  font-size: ${({ position }) => (position === 1 ? '21px' : '16px')};
  line-height: ${({ position, isHeader }) => (isHeader ? '14px' : position === 1 ? '26px' : '20px')};
`;

const Username = styled.span`
  flex: 1;
  font-family: 'SlussenExtended', sans-serif;
`;

export { Container, ListHeader, ListItem, PositionListItem, Rank, PortfolioValue, Username };
