import { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Container, ListHeader, ListItem, PositionListItem, Rank, Username, PortfolioValue } from './styles';
import GlobalStylesWrapper from '../GlobalStylesWrapper';
import Loader from '../Loader';

import { LEADERBOARD_QUERY } from '../../graphql/queries';
import usePolling from '../../utils/usePolling';
import { priceFormatter } from '../../utils/formatter';

interface LeaderboardItem {
  position: number;
  value: number;
  profile: {
    id: string;
    alias: string;
  };
}

const POLLING_INTERVAL = 10000;

export default function Leaderboard() {
  const params = new URLSearchParams(window.location.search);
  const args = params.get('args') || '';
  const [argName, argValue] = args.split(':');

  const { loading, error, data, fetchMore, refetch } = useQuery(LEADERBOARD_QUERY, { variables: { limit: 100, offset: 0, [argName]: argValue } });
  const { start } = usePolling({ interval: POLLING_INTERVAL, callback: () => refetch() });

  const records = data ? data?.portfolioLeaderboard : [];
  const [isTargetRowItemInView, setIsTargetRowItemInView] = useState(false);
  const middleListItemRef = useRef(null);

  const targetRowItemRef = useCallback(
    (node: Element | null) => {
      if (node) {
        const unobserve = observeRowItem(node, setIsTargetRowItemInView);

        return () => unobserve();
      }
    },
    [data],
  );

  useEffect(() => {
    const middleListItemRefNode = middleListItemRef.current;

    if (middleListItemRefNode) {
      const unobserve = observeMiddleOfList(middleListItemRefNode, () => {
        fetchMore({
          variables: { offset: records.length },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return {
              portfolioLeaderboard: [...prev.portfolioLeaderboard, ...fetchMoreResult.portfolioLeaderboard],
              userRanking: fetchMoreResult.userRanking,
            };
          },
        });
      });

      return () => unobserve();
    }
  }, [middleListItemRef, data]);

  if (loading) return <Loader color="#fff" />;
  if (error) return <p>Error :(</p>;

  start();

  const user = data?.userRanking || {};
  const { position: userPosition, profile: userProfile, value: userValue } = user || {};

  function observeRowItem(node: Element, callback: (arg0: boolean) => void) {
    const observer = new IntersectionObserver((entries) => {
      callback(entries[0].isIntersecting);
    });

    observer.observe(node);

    return () => observer.disconnect();
  }

  function observeMiddleOfList(node: Element, callback: { (): void; (): void }) {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        callback();
      }
    });

    observer.observe(node);

    return () => observer.disconnect();
  }

  return (
    <GlobalStylesWrapper>
      <Container>
        {data ? (
          <>
            <ListHeader>
              <Rank isHeader={true}>Rank</Rank>
              <PortfolioValue isHeader={true}>Portfolio Value</PortfolioValue>
            </ListHeader>

            {records.map((item: LeaderboardItem, index: number) => {
              const { position, profile, value } = item || {};
              // Get the halfway point of the list
              const isMiddleItem = index === data?.portfolioLeaderboard.length / 2;

              return (
                <ListItem //
                  ref={userPosition === position - 1 ? targetRowItemRef : null}
                  key={index}
                  position={position}
                  style={userPosition === position ? { backgroundColor: '#2945CC' } : {}}
                >
                  <Rank position={position} style={userPosition === position ? { color: '#ffffff' } : {}}>{`${position}`}</Rank>

                  <Username>{profile?.alias}</Username>
                  <PortfolioValue position={position} ref={isMiddleItem ? middleListItemRef : null}>
                    {priceFormatter(value)}
                  </PortfolioValue>
                </ListItem>
              );
            })}

            {userPosition && !isTargetRowItemInView && (
              <PositionListItem position={userPosition} isOutOfView={!isTargetRowItemInView}>
                <Rank position={userPosition} isOutOfView={!isTargetRowItemInView}>
                  {userPosition}
                </Rank>
                <Username>{userProfile?.alias}</Username>
                <PortfolioValue>{priceFormatter(userValue)}</PortfolioValue>
              </PositionListItem>
            )}
          </>
        ) : (
          <div style={{ margin: 20 }}>401 Unauthorized</div>
        )}
      </Container>
    </GlobalStylesWrapper>
  );
}
