import { gql } from '@apollo/client';

export const TOP_MOVERS_STOCKS_QUERY = gql`
  query TopMovers($leagueAbbreviation: String, $quantity: Float, $sortOrder: String) {
    topMovers(leagueAbbreviation: $leagueAbbreviation, quantity: $quantity, sortOrder: $sortOrder) {
      id
      athlete {
        firstName
        lastName
        mojoPosition
        headshotUrl
        leagueAbbreviation
        jerseyNumber
        team {
          abbreviation
        }
      }
      currentPriceFormatted
      dailyChangeDollarsFormatted
      dailyChangePercentage
      dailyChangePercentageFormatted
      type
    }
  }
`;

export const LEADERBOARD_QUERY = gql`
  query Leaderboard($limit: Int!, $offset: Int!, $contextId: ID!) {
    portfolioLeaderboard(limit: $limit, offset: $offset, contextId: $contextId) {
      position
      value
      profile {
        id
        alias
      }
    }
    userRanking(contextId: $contextId) {
      position
      value
      profile {
        id
        alias
      }
    }
  }
`;
