function getCookie(key: string) {
  return document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${key}=`))
    ?.split('=')[1];
}

function deleteCookie(key: string, domain: string) {
  if (getCookie(key)) {
    document.cookie = `${key}= ;domain=${domain};path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT; secure`;
  }
}

export default { getCookie, deleteCookie };
