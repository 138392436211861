import { useState, useRef } from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import { CheckIcon } from '@heroicons/react/solid';

import validation, { validateUSPhoneNumber } from '../../utils/validation';
import useFormInput from '../../utils/useFormInput';

import { ADD_ITERABLE_SUBSCRIBER_MUTATION } from '../../graphql/mutations';

import GlobalStylesWrapper from '../GlobalStylesWrapper';
import Input from '../Input';
import Loader from '../Loader';

interface FormProps {
  listId: number;
  buttonText?: string;
  placeholderText?: string;
  successMessage?: string;
  isPhone?: boolean;
  redirectUrl?: string;
}

export default function IterableForm(formInput: FormProps) {
  const [submitForm, { loading }] = useMutation(ADD_ITERABLE_SUBSCRIBER_MUTATION);
  const inputRef = useRef();

  const input = useFormInput({
    initialValue: '',
    validators: [formInput?.isPhone ? validation.isPhone({ message: 'Please enter a valid phone number' }) : validation.isEmail({ message: 'Please enter a valid email' }), validation.isRequired({ message: 'Required input' })],
  });

  const getRandomInt = (max: number) => Math.floor(Math.random() * max);
  const randomNum = getRandomInt(Date.now());
  const validatedPhoneNumber = validateUSPhoneNumber(input.value);

  const showError = input.touched && !!input.isValid && !input.isValid;

  const [error, setError] = useState() as any;
  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const variables = formInput?.isPhone
    ? {
        input: {
          listId: formInput.listId,
          email: Date.now() + '@' + randomNum + '.com',
          dataFields: {
            phoneNumber: validatedPhoneNumber,
          },
        },
      }
    : {
        input: {
          listId: formInput.listId,
          email: input.value,
          dataFields: {},
        },
      };

  if (success && formInput.redirectUrl !== undefined) {
    window.location.href = formInput.redirectUrl;
  }

  return (
    <GlobalStylesWrapper>
      <form
        onSubmit={async (e: any) => {
          e.preventDefault();
          e.target.disabled = true;

          try {
            const { data, errors } = await submitForm({ variables: { ...variables } });
            if (data && !errors) {
              setSuccess(true);
            }

            if (errors) {
              setError(errors[0]);
            }
          } catch (error) {
            console.error(error);
            setError(error);
          }
        }}
      >
        <StyledRow>
          {success ? (
            <StyledSuccessWrapper>
              <StyledCheckIcon>
                <CheckIcon />
              </StyledCheckIcon>
              <StyledMessage>{formInput?.successMessage || 'Thanks for signing up.'}</StyledMessage>
            </StyledSuccessWrapper>
          ) : (
            <>
              <StyledInput ref={inputRef} type={formInput?.isPhone ? 'phone' : 'email'} placeholder={formInput?.placeholderText} {...input.bind} />
              {showError || !input.isValid ? null : <StyledButton type="submit">{loading ? <Loader button /> : formInput?.buttonText}</StyledButton>}
            </>
          )}
        </StyledRow>
        {(showError || error) && (
          <StyledRow>
            <StyledErrorMessage>{input.errorMessage || error.message}</StyledErrorMessage>
          </StyledRow>
        )}
      </form>
    </GlobalStylesWrapper>
  );
}

const StyledRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

const StyledInput: any = styled(Input)`
  width: 100%;
  border: 1px solid #c1c2bf;
  border-radius: 12px;
  flex: 2;
  background: transparent;
  padding: 6px 8px;
  margin-bottom: 8px;
  min-height: 48px;
  font-size: 16px;
  line-height: 24.5px;
  color: rgba(235, 235, 245, 0.7);

  &:focus,
  &:focus-within {
    outline: none;
    border-color: #3898ec;
  }

  &::placeholder {
    color: #ebebf5;
    opacity: 0.7;
  }

  @media (min-width: 600px) {
    margin-right: 8px;
    margin-bottom: 0;
  }
`;

const StyledButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: #bfff00;
  text-transform: uppercase;
  padding: 6px 24px;
  height: 48px;
  width: 100%;
  min-width: 120px;
  font-weight: bold;
  font-size: 13px;
  line-height: 24.5px;
  letter-spacing: 0.5;
  font-family: 'SlussenExtended', sans-serif;
  border: 0;

  &:disabled {
    cursor: not-allowed;
    background-color: rgba(191, 255, 0, 0.6);
  }

  @media (min-width: 600px) {
    width: auto;
  }
`;

const StyledErrorMessage = styled.div`
  color: #ff5542;
  font-size: 13px;
  padding: 4px 0;
`;

const StyledSuccessWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
`;

const StyledCheckIcon = styled.div`
  width: 48px;
  height: 48px;
  margin-right: 6px;
  color: #bfff00;

  svg {
    width: 48px;
    height: 48px;
  }
`;

const StyledMessage = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-family: 'SlussenExtended', sans-serif;
`;
