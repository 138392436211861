import { useState, useRef } from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import { CheckIcon } from '@heroicons/react/solid';

import validation from '../../utils/validation';
import useFormInput from '../../utils/useFormInput';

import GlobalStylesWrapper from '../GlobalStylesWrapper';
import Input from '../Input';

import { ADD_ITERABLE_SUBSCRIBER_MUTATION } from '../../graphql/mutations';
import Loader from '../Loader';

interface FormProps {
  buttonText?: string;
  placeholderText?: string;
  listId: number;
}

export default function IterableEmailForm({ buttonText = 'submit', placeholderText = 'email...', listId }: FormProps) {
  const [submitEmail, { loading }] = useMutation(ADD_ITERABLE_SUBSCRIBER_MUTATION);

  const emailInputRef = useRef();

  const emailInput = useFormInput({ initialValue: '', validators: [validation.isEmail({ message: 'Please enter a valid email' }), validation.isRequired({ message: 'Email is required' })] });

  const showError = emailInput.touched && emailInput.isValid !== null && !emailInput.isValid;

  const [error, setError] = useState() as any;
  const [success, setSuccess] = useState(false);

  return (
    <GlobalStylesWrapper>
      <form
        onSubmit={async (e: any) => {
          e.preventDefault();
          e.target.disabled = true;

          try {
            const { data, errors } = await submitEmail({ variables: { listId: listId, emailAddress: emailInput.value } });
            if (data && !errors) {
              setSuccess(true);
            }

            if (errors) {
              setError(errors[0]);
            }
          } catch (error) {
            console.error(error);
            setError(error);
          }
        }}
      >
        <StyledRow>
          {success ? (
            <StyledSuccessWrapper>
              <StyledCheckIcon>
                <CheckIcon />
              </StyledCheckIcon>
              <StyledMessage>You're on the list! Thanks for signing up.</StyledMessage>
            </StyledSuccessWrapper>
          ) : (
            <>
              <StyledInput ref={emailInputRef} type="email" placeholder={placeholderText} {...emailInput.bind} />
              <StyledButton disabled={showError || !emailInput.touched} type="submit">
                {loading ? <Loader button /> : buttonText}
              </StyledButton>
            </>
          )}
        </StyledRow>
        {(showError || error) && (
          <StyledRow>
            <StyledErrorMessage>{emailInput.errorMessage || error.message}</StyledErrorMessage>
          </StyledRow>
        )}
      </form>
    </GlobalStylesWrapper>
  );
}

const StyledRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

const StyledInput: any = styled(Input)`
  width: 100%;
  border: 1px solid #c1c2bf;
  border-radius: 12px;
  flex: 2;
  background: transparent;
  padding: 6px 8px;
  margin-bottom: 8px;
  min-height: 48px;
  font-size: 16px;
  line-height: 24.5px;
  color: rgba(235, 235, 245, 0.7);

  &:focus,
  &:focus-within {
    outline: none;
    border-color: #3898ec;
  }

  &::placeholder {
    color: #ebebf5;
    opacity: 0.7;
  }

  @media (min-width: 600px) {
    margin-right: 8px;
    margin-bottom: 0;
  }
`;

const StyledButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: #bfff00;
  text-transform: uppercase;
  padding: 6px 24px;
  height: 48px;
  width: 100%;
  min-width: 120px;
  font-weight: bold;
  font-size: 13px;
  line-height: 24.5px;
  letter-spacing: 0.5;
  font-family: 'SlussenExtended', sans-serif;
  border: 0;

  &:disabled {
    cursor: not-allowed;
    background-color: rgba(191, 255, 0, 0.6);
  }

  @media (min-width: 600px) {
    width: auto;
  }
`;

const StyledErrorMessage = styled.div`
  color: #ff5542;
  font-size: 13px;
  padding: 4px 0;
`;

const StyledSuccessWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
`;

const StyledCheckIcon = styled.div`
  width: 48px;
  height: 48px;
  margin-right: 6px;
  color: #bfff00;

  svg {
    width: 48px;
    height: 48px;
  }
`;

const StyledMessage = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-family: 'SlussenExtended', sans-serif;
`;
