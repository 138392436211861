import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import GlobalStylesWrapper from '../GlobalStylesWrapper';
import Logo from '../../icons/MojoLogo';

export default function MojoLogo({ width = '200px', light = false, mono = false }) {
  return (
    <GlobalStylesWrapper>
      <StyledLogoWrapper width={width}>
        <Logo light={light} mono={mono} width={width} />
      </StyledLogoWrapper>
    </GlobalStylesWrapper>
  );
}

const StyledLogoWrapper: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: ${(props: any) => props.width}px;
`;

MojoLogo.propTypes = {
  width: PropTypes.string,
  light: PropTypes.bool,
  mono: PropTypes.bool,
};
