import { gql } from '@apollo/client';

export const SUBMIT_REFERRAL_MUTATION = gql`
  mutation SubmitReferralMutation($referralCode: String!, $referredUserEmail: EmailAddress!) {
    referUserEmail(referralCode: $referralCode, referredUserEmail: $referredUserEmail) {
      isEligible
    }
  }
`;

export const REDEEM_QR_PROMO_MUTATION = gql`
  mutation RedeemQRPromoMutation($promoCampaignId: UUID!, $email: EmailAddress!) {
    redeemDirectPromoByEmail(email: $email, promoCampaignId: $promoCampaignId) {
      isEligible
    }
  }
`;

export const ADD_ITERABLE_SUBSCRIBER_MUTATION = gql`
  mutation AddSubscriberToList($input: IterableSubscriberFormInput!) {
    addSubscriberToList(input: $input) {
      successCount
      failCount
    }
  }
`;
